// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-datenschutz-mdx": () => import("./../../../src/pages/datenschutz.mdx" /* webpackChunkName: "component---src-pages-datenschutz-mdx" */),
  "component---src-pages-gruen-mdx": () => import("./../../../src/pages/gruen.mdx" /* webpackChunkName: "component---src-pages-gruen-mdx" */),
  "component---src-pages-impressum-mdx": () => import("./../../../src/pages/impressum.mdx" /* webpackChunkName: "component---src-pages-impressum-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-was-ich-spannend-finde-mdx": () => import("./../../../src/pages/was-ich-spannend-finde.mdx" /* webpackChunkName: "component---src-pages-was-ich-spannend-finde-mdx" */)
}

